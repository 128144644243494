let header = document.querySelector('header');
const breakpoint = 900;
let bookDemo = document.querySelector(".book-demo-form-container");
let body = document.querySelector('body');
let dropdownMenu = document.querySelector(".dropdown-menu");

let prevActiveDropdownMenuLink = null;
let prevActiveMenuLink = null;
let prevDropdownLinksDropdownElements = null;

if(bookDemo) {
    let bookDemoButton = document.querySelectorAll(".book-demo-button");
    let exitDemoButton = document.querySelector(".exit-demo-form");
    let exitDemoArea = document.querySelector(".exit-demo-form-area");
    let contactForm7Submit = bookDemo.querySelector(".wpcf7-submit");
    let bookDemoSubmit = bookDemo.querySelector(".submit-form-button");

    bookDemoButton.forEach(button => {
        button.addEventListener("click", (event) => {
            if(bookDemo.classList.contains('active')) {
                turnInActive(bookDemo);
                body.style.overflow = "visible";
            } else {
                turnActive(bookDemo);
                body.style.overflow = "hidden";
            }
        });
    });

    exitDemoButton.addEventListener("click", (event) => {
        turnInActive(bookDemo);
        body.style.overflow = "visible";
    });

    exitDemoArea.addEventListener("click", (event) => {
        turnInActive(bookDemo);
        body.style.overflow = "visible";
    });

    bookDemoSubmit.addEventListener("click", (event) => {
        contactForm7Submit.click();
    });
}

if (window.innerWidth > breakpoint) {
	window.addEventListener("load", (event) => {
		let interactiveBlub = document.querySelector(".interactive-blub");
		let interactiveBlubDropdown = document.querySelector(".interactive-blub-dropdown");
		let menu = document.querySelector(".main-menu");
		let menuElements = document.querySelectorAll(".menu-elements");
		let menuDropdownElements = document.querySelectorAll(".menu .menu-elements.dropdown");
		let dropdownElementsWrapper = document.querySelectorAll(".dropdown-menu-wrapper");
        let dropdownLinksDropdownElements = document.querySelectorAll(".menu-links-dropdown-elements");
        let featuredItem = document.querySelectorAll(".featured-item");
		
        let dropdownMenuElements = document.querySelectorAll(".dropdown-menu-element");

        let dropdownMenuLinks = document.querySelectorAll(".menu-links");

        let prevFeaturedContainer = null;
        
        let maxHeight = 0;

        dropdownMenuLinks.forEach((element, index) => {
            if(element.offsetHeight > maxHeight) {
                maxHeight = element.offsetHeight;
            }
        });

        document.addEventListener('click', function(event) {
            const outsideClick = !header.contains(event.target);
            if(outsideClick && dropdownMenu.classList.contains('active')) {
                toggleDropdown(dropdownMenu, false);
                setTimeout(() => {
                    clearActiveClasses();
                }, 500);
            }
        });

		menuElements.forEach((element, index) => {
			element.addEventListener("mouseover", () => {
				adjustBlubToElement(element, interactiveBlub);
			});

			element.addEventListener("mouseout", () => {
				restoreWidth(menu, interactiveBlub);
			});
		});

        dropdownMenuElements.forEach((element, index) => {
            element.addEventListener("mouseover", () => {
				adjustBlubToElement(element, interactiveBlubDropdown);
			});

			element.addEventListener("mouseout", () => {
                adjustBlubToElement(prevActiveDropdownMenuLink, interactiveBlubDropdown, true);
			});
        });

		menuDropdownElements.forEach((element, index) => {
			element.addEventListener("click", () => {
				toggleDropdown(dropdownMenu, true);
				adjustBlubToElement(element, interactiveBlubDropdown);
				
                if (prevActiveMenuLink != null) {
                    turnInActive(prevActiveMenuLink);
                    turnInActive(prevActiveDropdownMenuLink);
				}
                
                if(prevFeaturedContainer != null) {
                    turnInActive(prevFeaturedContainer);
                }

                turnActive(element);
                turnActive(dropdownElementsWrapper[index]);
                turnActive(dropdownLinksDropdownElements[index]);
                turnActive(featuredItem[index]);
    
                prevDropdownLinksDropdownElements = dropdownLinksDropdownElements[index];
                prevFeaturedContainer = featuredItem[index];
                prevActiveDropdownMenuLink = dropdownElementsWrapper[index];
				prevActiveMenuLink = element;
			});
		});



        dropdownElementsWrapper.forEach((element, index) => {
			element.addEventListener("click", () => {
				adjustBlubToElement(element, interactiveBlubDropdown);
                
                if(element.classList.contains('active')) {
                    toggleDropdown(dropdownMenu, false);
                    setTimeout(() => {
                        clearActiveClasses();
                    }, 500);
                } else {
                    turnInActive(prevActiveDropdownMenuLink);
                    turnInActive(prevActiveMenuLink);
                    
                    turnActive(element);
                    turnActive(menuDropdownElements[index]);
                    turnActive(dropdownLinksDropdownElements[index]);
    
                    turnInActive(prevDropdownLinksDropdownElements);
                    turnInActive(prevFeaturedContainer);
                    turnActive(featuredItem[index]);
                    
                    prevDropdownLinksDropdownElements = dropdownLinksDropdownElements[index];
                    prevFeaturedContainer = featuredItem[index];
                    prevActiveDropdownMenuLink = element;
                    prevActiveMenuLink = menuDropdownElements[index];
                }

			});
		});
	});
} else {
    let body = document.querySelector('body');
    let mobileMenuButton = document.querySelector('.mobile-menu-button');
    let mobileMenu = document.querySelector('.mobile-menu');
    let mainMobileMenu = document.querySelector('.main-mobile-menu');

    let mainLinksExpandable = document.querySelectorAll('.main-link.expandable');
    let subMenu = document.querySelector('.sub-menu-items');
    let subMenuElements = document.querySelectorAll('.sub-menu-elements');
    
    let subMenuParent = document.querySelectorAll('.sub-menu-parent');

    mobileMenuButton.addEventListener('click', () => {
        if(mobileMenu.classList.contains('active')) {
            mobileMenuButton.classList.remove('active');
            mainMobileMenu.classList.remove('active');
            mobileMenu.classList.remove('active');
            subMenu.classList.remove('active');
            body.style.overflow = "visible";
            mainLinksExpandable.forEach((element, index) => {
                element.classList.remove('active');
                subMenuElements[index].classList.remove('active');
            });
        } else {
            mobileMenuButton.classList.add('active');
            mobileMenu.classList.add('active');
            body.style.overflow = "hidden";
        }
    });

    mainLinksExpandable.forEach((element, index) => {
        element.addEventListener('click', () => {
            if(element.classList.contains('active')) {
                element.classList.remove('active');
                subMenu.classList.remove('active');
                mainMobileMenu.classList.remove('active');
                subMenuElements[index].classList.remove('active');
            } else {
                element.classList.add('active');
                subMenu.classList.add('active');
                mainMobileMenu.classList.add('active');
                subMenuElements[index].classList.add('active');
            }
        });
    });

    subMenuParent.forEach((element, index) => {
        element.addEventListener('click', () => {
            subMenu.classList.remove('active');
            mainMobileMenu.classList.remove('active');
            flushActiveClassForAllElements(mainLinksExpandable);
            flushActiveClassForAllElements(subMenuElements);
        });
    });
}

function turnActive(element) {
    if(element){
        element.classList.add("active");
    }
}

function turnInActive(element) {
    if(element) {
        element.classList.remove("active");
    }
}

function flushActiveClassForAllElements(elements) { 
    elements.forEach((element, index) => {
        element.classList.remove("active");
    });
}

function adjustBlubToElement(element, blub) {
	let elementWidth = element.offsetWidth;
	let elementLeft = element.offsetLeft;
	let elementTop = element.offsetTop;

	blub.style.width = elementWidth + "px";
	blub.style.left = elementLeft + "px";
	blub.style.top = elementTop + "px";
}

function restoreWidth(partent, blub, offsetLeft) {
	let elementWidth = partent.offsetWidth;
	blub.style.width = elementWidth + "px";
    if(offsetLeft) {
        let elementLeft = partent.offsetLeft;
        blub.style.left = elementLeft+"px";
    } else {
        blub.style.left = "0px";
    }
}

function toggleDropdown(dropdown, display) {
	if (display) {
		dropdown.style.transform = "translateY(0%)";
        dropdown.classList.add('active');
	} else {
		dropdown.style.transform = "translateY(-100%)";
        dropdown.classList.remove('active');
	}
}

function clearActiveClasses() { 
    turnInActive(prevActiveDropdownMenuLink);
    turnInActive(prevActiveMenuLink);
    turnInActive(prevDropdownLinksDropdownElements);
}

export function closeDropdown() {
    toggleDropdown(dropdownMenu, false);
    setTimeout(() => {
        clearActiveClasses();
    }, 500);
}